/* eslint-disable react/no-danger */
import React from 'react';
import Router from 'next/router';
import Product from '../../../plugin/ProductList';
import useStyles from './style';

// sementara di comment dlu, untuk custom filter memakai aggregations product
// import { getFilter } from '../../../services/graphql';

const categoryTabs = (category) => {
    const data = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < category.length; index++) {
        data.push(category[index].name);
    }
    return data;
};

const CategoryPage = ({
    data, storeConfig, t, BannerView, BreadcrumbView, TabView, ...other
}) => {
    const styles = useStyles();
    const categoryList = data.categoryList[0];
    const handleChange = (event, newValue) => {
        Router.push(
            '/[...slug]',
            `/${categoryList.children[newValue - 1].url_path}`,
        );
    };
    // sementara di comment dlu, untuk custom filter memakai aggregations product
    // const customFilter = getFilter(categoryList.id);
    let breadcrumbsData = [];
    if (categoryList.breadcrumbs && categoryList.breadcrumbs.length > 0) {
        breadcrumbsData = categoryList.breadcrumbs.map((bc) => ({
            label: bc.category_name,
            link: `/${bc.category_url_path}`,
            active: false,
            id: bc.category_id,
        }));
    }
    breadcrumbsData.push({
        label: categoryList.name,
        link: '#',
        active: true,
    });
    return (
        <>
            <style jsx>
                {`
                    .cms-block-category :global(img) {
                        width: 100%;
                        max-width: 100%;
                    }
                `}
            </style>
            <div className={styles.container}>
                {
                    categoryList
                    && (!categoryList.display_mode || categoryList.display_mode === 'PRODUCTS_AND_PAGE' || categoryList.display_mode === 'PRODUCTS')
                    && (
                        <>
                            <Product
                                // sementara di comment dlu, untuk custom filter memakai aggregations product
                                // customFilter={customFilter.loading ? [] : customFilter.data.getFilterAttributeOptions.data}
                                catId={categoryList.id}
                                categoryPath={categoryList.url_path}
                                catalog_search_engine={storeConfig.catalog_search_engine}
                                t={t}
                                category={categoryTabs(categoryList.children)}
                                dataTabs={categoryTabs(categoryList.children)}
                                onChangeTabs={handleChange}
                                storeConfig={storeConfig}
                                {...other}
                            />
                        </>
                    )
                }
            </div>
        </>
    );
};

export default CategoryPage;
