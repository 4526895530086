import TabView from '@common_tabs';
import ErrorMessage from '@core_modules/catalog/plugin/ProductList/components/ErrorMessage';
import ProductListSkeleton from '@core_modules/catalog/plugin/ProductList/components/ProductListSkeleton';
import ImageProductView from '@core_modules/catalog/plugin/ProductItem/components/Image';
import DetailProductView from '@core_modules/catalog/plugin/ProductItem/components/Detail';
import FilterView from '@modules/catalog/plugin/ProductList/components/Filter/view';
import FilterModalView from '@modules/catalog/plugin/ProductList/components/Filter/FilterDialog';
import CoreBase from './core';

const Page = (props) => (
    <CoreBase
        ErrorMessage={ErrorMessage}
        ProductListSkeleton={ProductListSkeleton}
        ImageProductView={ImageProductView}
        DetailProductView={DetailProductView}
        TabView={TabView}
        FilterView={FilterView}
        FilterModalView={FilterModalView}
        {...props}
    />
);

export default Page;
