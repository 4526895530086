/* eslint-disable no-nested-ternary */
import React from 'react';
import Link from 'next/link';
import { features } from '@config';
import setDefaultWhenEmpty from '@helper_checkimagesrc';
import { breakPointsUp } from '@helper_theme';
import classNames from 'classnames';
import useStyles from '@core_modules/commons/Slick/Banner/style';
// import Thumbor from './Thumbor';
import Thumbor from '@common_imageThumbor';

/**
 slug page need props 'href' & 'as' to prevent browser reloading
 *isSlug == true => <link href="/[...slug]" as={link} />
 *isSlug == false => <link href={link} />
*/

const ImageSlide = ({
    imageUrl = '', link = '#', isSlug = false, width, height, mobileImageUrl = '', noLink, useThumbor,
    contentWidth, customClass = '', lazy, preload = false, isHomepageBanner = false, widthMobile, heightMobile,
}) => {
    const styles = useStyles();
    const href = link && link[0] === '/' ? link : link.includes('http') ? link : `/${link}`;
    const isNewTab = link && link.includes('http');
    const desktop = breakPointsUp('sm');
    const defaultImageUrl = typeof window !== 'undefined' && mobileImageUrl
        ? desktop ? setDefaultWhenEmpty(imageUrl) : setDefaultWhenEmpty(mobileImageUrl) : setDefaultWhenEmpty(imageUrl);
    if (noLink) {
        return (
            useThumbor
                ? (
                    <Thumbor
                        src={imageUrl}
                        srcMobile={mobileImageUrl}
                        alt={href}
                        width={width || features.imageSize.homeSlider.desktop.width}
                        height={height || features.imageSize.homeSlider.desktop.height}
                        widthMobile={widthMobile || features.imageSize.homeSlider.mobile.width}
                        heightMobile={heightMobile || features.imageSize.homeSlider.mobile.height}
                        quality={50}
                        lazy={lazy}
                        preload={preload}
                        isHomepageBanner={isHomepageBanner}
                        className={contentWidth === 'auto' ? classNames(styles.imageSliderAuto, styles.imageSlider, customClass)
                            : classNames(styles.imageSlider, customClass)}
                    />
                ) : (
                    <img src={defaultImageUrl} alt="" style={{ width: '100%' }} />
                )
        );
    }
    return (
        <Link
            href={isSlug ? '/[...slug]' : href}
            {...(isSlug && { as: href })}
        >
            <a target={isNewTab ? '_blank' : '_self'}>
                {useThumbor && (
                    <Thumbor
                        src={imageUrl}
                        srcMobile={mobileImageUrl}
                        alt={href}
                        width={width || features.imageSize.homeSlider.desktop.width}
                        height={height || features.imageSize.homeSlider.desktop.height}
                        widthMobile={widthMobile || features.imageSize.homeSlider.mobile.width}
                        heightMobile={heightMobile || features.imageSize.homeSlider.mobile.height}
                        quality={70}
                        className={contentWidth === 'auto' ? classNames(styles.imageSliderAuto, styles.imageSlider, customClass)
                            : classNames(styles.imageSlider, customClass)}
                        contentWidth={contentWidth}
                        customClass={customClass}
                        lazy={lazy}
                        preload={preload}
                        isHomepageBanner={isHomepageBanner}
                    />
                )}
                {!useThumbor && (
                    <img src={defaultImageUrl} alt="" style={{ width: '100%' }} />
                )}
            </a>
        </Link>
    );
};

export default ImageSlide;
