/* eslint-disable react/jsx-one-expression-per-line */
import useStyles from '../style';

const View = (props) => {
    const styles = useStyles();
    // const { time, link } = props;
    const { time } = props;

    const getTextTime = (number) => (number > 9 ? number : (`0${number || 0}`));

    return (
        <div className={styles.root}>
            <div className={styles.centerFlex}>
                <div className={styles.timeContainer}>
                    {getTextTime(time && time.days)}
                    {/* <span className={styles.timeDesc}>{t('common:time:day')}</span> */}
                </div>
                <div className={styles.spacer}>:</div>
                <div className={styles.timeContainer}>
                    <span className={styles.time}>
                        {getTextTime(time && time.hours)}
                        {/* <span className={styles.timeDesc}>{t('common:time:hour')}</span> */}
                    </span>
                </div>
                <div className={styles.spacer}>:</div>
                <div className={styles.timeContainer}>
                    <span className={styles.time}>
                        {getTextTime(time && time.minutes)}
                        {/* <span className={styles.timeDesc}>{t('common:time:minute')}</span> */}
                    </span>
                </div>
                <div className={styles.spacer}>:</div>
                <div className={styles.timeContainer}>
                    <span className={styles.time}>
                        {getTextTime(time && time.seconds)}
                        {/* <span className={styles.timeDesc}>{t('common:time:second')}</span> */}
                    </span>
                </div>
            </div>
            {/* <div className={classNames(styles.endFlex)}> */}
            {/*     <Link href="/[...slug]" as={link}> */}
            {/*         <a> */}
            {/*             <span className={styles.viewAll}>{t('common:button:viewAll')} </span> */}
            {/*             <img src="/assets/img/arrow.svg" alt="" /> */}
            {/*         </a> */}
            {/*     </Link> */}
            {/* </div> */}
        </div>
    );
};

export default View;
