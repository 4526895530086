import { useTranslation } from '@i18n';

/* eslint-disable react/jsx-one-expression-per-line */
const useTimer = (endTime) => {
    const [timeLeft, setTimeLeft] = React.useState(endTime - Date.now());
    React.useEffect(() => {
        setTimeout(() => {
            setTimeLeft(timeLeft - 1000);
        }, 1000);
    });

    return [{
        days: Math.floor(timeLeft / (1000 * 60 * 60 * 24)),
        hours: Math.floor((timeLeft / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((timeLeft / 1000 / 60) % 60),
        seconds: Math.floor((timeLeft / 1000) % 60),
    }];
};

const Core = (props) => {
    const { flashSaleData, View, link } = props;
    const endFlashSaleDate = new Date(flashSaleData.flash_sale_to).getTime();
    const isFlashSale = Date.now() < endFlashSaleDate;
    const [time] = useTimer(endFlashSaleDate);
    const { t } = useTranslation(['home']);

    if (!isFlashSale || !flashSaleData.show_countdown) return null;

    return <View t={t} time={time} link={link} />;
};

export default Core;
