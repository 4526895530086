import ImageProductView from '@modules/catalog/plugin/ProductItem/components/Image';
import DetailProductView from '@modules/catalog/plugin/ProductItem/components/Detail';
import LabelView from '@core_modules/catalog/plugin/ProductItem/components/LabelView';
import ConfigurableView from '@core_modules/catalog/plugin/ProductItem/components/ConfigurableProductItem/view';
import Core from './core';

const ProductItem = (props) => (
    <Core
        {...props}
        ImageProductView={ImageProductView}
        DetailProductView={DetailProductView}
        LabelView={LabelView}
        ConfigurableView={ConfigurableView}
    />
);

export default ProductItem;
