/* eslint-disable eqeqeq */
import React from 'react';
import { getStoreSwitcher } from '@modules/theme/services/graphql';
import { getVendor as getVendorCookies } from '@helper_cookies';

const Core = (props) => {
    const {
        Content, t, product, StoresDialog, STORE_PICKUP, DELIVERY, selectedShippingMethod, setSelectedShippingMethod, getAvailableSource,
    } = props;
    const [selectedVendor, setSelectedVendor] = React.useState();
    const { data, loading, called } = getStoreSwitcher();
    const vendors = data
        && data.storeSwitcher
        && data.storeSwitcher.options
        && data.storeSwitcher.options.length
        ? (
            data.storeSwitcher.options.map((option) => (
                { label: option.vendor_name, value: option.vendor_id }
            ))
        ) : [];
    const availableSource = getAvailableSource.data
        && getAvailableSource.data.getAvailableSource
        && getAvailableSource.data.getAvailableSource.list
        && getAvailableSource.data.getAvailableSource.list.filter((source) => source.stock_qty > 0);

    const setSelectedVendorById = (id) => {
        const obj = vendors.find((vendor) => vendor.value == id);
        setSelectedVendor(obj);
    };

    React.useEffect(() => {
        const getInitialVendor = () => {
            const getVendor = (id) => vendors.find((vendor) => vendor.value == id);
            if (getVendorCookies()) {
                return getVendor(getVendorCookies());
            }
            if (data && data.storeSwitcher && data.storeSwitcher.default_vendor_id) {
                return getVendor(data.storeSwitcher.default_vendor_id);
            }
            return null;
        };
        if (!loading && called) {
            setSelectedVendor(getInitialVendor());
        }
    }, [loading, called]);

    return (
        <Content
            {...{
                t,
                product,
                STORE_PICKUP,
                DELIVERY,
                vendors,
                selectedVendor,
                setSelectedVendor,
                setSelectedVendorById,
                selectedShippingMethod,
                setSelectedShippingMethod,
                availableSource,
                StoresDialog,
            }}
        />
    );
};

export default Core;
