import makeStyles from '@material-ui/core/styles/makeStyles';
import { CreateBorder, CreateMargin, CreatePadding } from '@theme_mixins';
import { PRIMARY, WHITE } from '@theme_color';
import { FONT_DEFAULT, FONT_REGULAR } from '@theme_typography';

export default makeStyles((theme) => ({
    root: {
        width: '100%',
        ...CreateMargin(15, 0, 15, 0),
        ...CreateBorder('1px', 0, 0, 0, PRIMARY),
    },
    expandContainer: {
        boxShadow: 'none',
        borderRadius: 'none',
        margin: 0,
    },
    headerExpand: {
        padding: 0,
        height: 40,
        ...CreateBorder(0, 0, '1px', 0, PRIMARY),
    },
    bodyExpand: {
        ...CreatePadding(0, 0, 20, 0),
        margin: 0,
    },
    headerOpen: {
        borderBottom: 'none !important',
    },
    bodyOpen: {
        ...CreateBorder(0, 0, '1px', 0, PRIMARY),
    },
    icon: {
        fontSize: 16,
        color: PRIMARY,
    },
    descriptionHtml: {
        fontFamily: 'Montserrat',
        fontSize: 16,
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
            width: '100%',
            height: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            // width: 800,
            width: '100%',
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            width: 320,
            height: '100%',
        },
        '& img': {
            width: '100%',
            height: '100%',
            [theme.breakpoints.up('md')]: {
                maxWidth: 800,
            },
            [theme.breakpoints.up('sm')]: {
                maxWidth: 650,
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: 300,
            },
        },
        '& iframe': {
            width: '100%',
            height: '100%',
            [theme.breakpoints.up('md')]: {
                maxWidth: 800,
            },
            [theme.breakpoints.up('sm')]: {
                maxWidth: 650,
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: 300,
            },
        },
    },

    listLabel: {
        ...FONT_REGULAR,
        fontSize: '20px',
    },
    listValue: {
        ...FONT_DEFAULT,
        fontSize: '20px',
    },
    tabs: {
        boxShadow: 'none',
        backgroundColor: WHITE,
        alignItems: 'center',
        borderBottom: '1px solid #B1B1B1',
        '& .tabsHeader': {
            letterSpacing: '0.75px',
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '27px',
            fontFamily: 'HartWell',
        },
        '& .MuiTab-root': {
            maxWidth: 'unset',
        },
    },
    iframeWrapper: {
        width: '100%',
        height: '530px',
        '& iframe': {
            width: '100%',
            height: '100%',
            minHeight: '320px',
            border: 'none',
            [theme.breakpoints.down('sm')]: {
                height: 'auto',
            },
        },
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
}));
