import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    BLACK, WHITE, BLUE_LIGHT, GRAY_BACKGROUND,
} from '@theme_color';

const useStyles = makeStyles({
    root: {
        margin: '16px 0',
    },
    boxWrapper: {
        padding: 6,
        '@media (max-width: 767px )': {
            padding: 8,
        }
    },
    box: {
        height: '100%',
        border: '2px solid #D2D2D2',
        borderRadius: 6,
        backgroundColor: WHITE,
        padding: 16,
        cursor: 'pointer',
        '& .checkIcon': {
            fontSize: 18,
            margin: '0 4px -4px 0',
            color: '#12B200',
        },
        '&:hover': {
            border: '2px solid #2E7BBF',
            backgroundColor: WHITE,
            color: '#2E7BBF',
            '& .checkIcon': {
                fontSize: 18,
                margin: '0 4px -4px 0',
                color: '#12B200',
            },
            '& .vendorAvailable': {
                color: '#000000',
            },
        },
        '&.selected': {
            border: '2px solid #2E7BBF',
            backgroundColor: WHITE,
            color: '#2E7BBF',
            '& .checkIcon': {
                fontSize: 18,
                margin: '0 4px -4px 0',
                color: '#12B200',
            },
            '& .selectedWrapper': {
                color: '#757575',
            },
            '& .vendorAvailable': {
                color: '#000000',
            },
        },
        position: 'relative',
        paddingBottom: 48,

    },
    top: {
        display: 'flex',
    },
    topDesc: {
        marginLeft: 12,
    },
    shippingMethod: {
        fontWeight: 'bold',
        fontSize: '18px',
    },
    vendorName: {
        marginTop: 2,
        fontSize: 12,
    },
    available: {
        margin: '16px 0',
    },
    button: {
        borderRadius: 4,
        position: 'absolute',
        bottom: 16,
        width: 'calc(100% - 32px)',
        backgroundColor: WHITE,
        color: BLUE_LIGHT,
        textTransform: 'capitalize',
        border: '1px solid #D2D2D2',
        '&:hover': {
            backgroundColor: GRAY_BACKGROUND,
        },
    },
    imgContainer: {
        marginTop: 5,
        height: 36,
        '& img': {
            height: '100%',
        },
    },
});

export default useStyles;
