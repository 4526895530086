import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_THIRD } from '@theme_color';
import {
    FlexColumn, FlexRow, CreateMargin,
} from '@theme_mixins';

export const useStyles = makeStyles(() => ({
    container: {
        ...FlexColumn,
        flexWrap: 'wrap',
        padding: '0 0',
    },
    spanLabelPrice: {
        ...FlexRow,
        justifyContent: 'space-between',
        ...CreateMargin(17, 0, 0, 0),
    },
}));

export const sliderStyle = {
    root: {
        color: GRAY_THIRD,
        width: '100%',
    },
    thumb: {
        height: 17,
        width: 17,
        backgroundColor: 'black',
    },
    active: {},
    track: {
        height: 8,
        borderRadius: 0,
    },
    rail: {
        height: 8,
        borderRadius: 0,
    },
};
