/* eslint-disable import/prefer-default-export */

import { gql } from '@apollo/client';
import { features, modules } from '@config';
/**
 * generate dynamic filter query
 * @param catId number
 * @param filter array of filter value
 * @returns string query to generate on grapql tag
 */
const filterProduct = (filter) => {
    let queryFilter = '{ ';
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < filter.length; index++) {
        const detailFilter = filter[index];
        if (detailFilter.type === 'price') {
            queryFilter += `
          ,${detailFilter.type} : {
            from: "${detailFilter.from}"
            to: "${detailFilter.to}"
          }
        `;
        } else if (typeof detailFilter.value === 'object') {
            let inFilter = '';
            // eslint-disable-next-line no-plusplus
            for (let idx = 0; idx < detailFilter.value.length; idx++) {
                inFilter += `${idx !== 0 ? ',' : ''}"${detailFilter.value[idx]}"`;
            }
            queryFilter += `${index !== 0 ? ',' : ''} ${detailFilter.type} : {
                in: [${inFilter}]
              }`;
        } else {
            queryFilter += `${index !== 0 ? ',' : ''} ${detailFilter.type} : {
                  eq: "${detailFilter.value}"
                }`;
        }
    }
    queryFilter += filter.length > 1
        ? 'e_catalog: {in: ["E-Catalog and Website", "E-Catalog"]}'
        : ',e_catalog: {in: ["E-Catalog and Website", "E-Catalog"]}';
    queryFilter += '}';
    return queryFilter;
};

export const getProductAgragations = () => gql`
  {
    products(search:"") {
      aggregations {
        attribute_code
      }
    }
  }
`;

/**
 * scema dynamic product
 * @param catId number
 * @param config Object {pageSize: number, currentPage: Number}
 * @returns grapql query
 */

const vendorProductData = (vendorId) => `
  vendor_product_data(selectedStore: ${vendorId}) {
    vendor_id
    vendor_cost
    vendor_stock
    vendor_special_price
    flash_sale_price
    flash_sale_from
    flash_sale_to
    show_countdown
  }
`;

export const getProduct = (config = {}) => gql`
  query getProducts(
    $pageSize: Int,
    $currentPage: Int,
  ){
  products( search: "${config.search}" ,filter: ${filterProduct(config.filter)},
  pageSize: $pageSize,
  currentPage: $currentPage
  ${config.sort && config.sort.key && config.sort.key !== 'position'
        ? `, sort: {${config.sort.key} : ${config.sort.value}}`
        : ''
}
    ) {
      total_count
      ${!config.customFilter
        ? `aggregations {
        attribute_code
        label
        options {
          count
          label
          value
        }
      }` : ''}
      __typename
      items {
        ${vendorProductData(config.vendorId)}
        id
        sku
        name
        url_key
        ${modules.catalog.productListing.configurableOptions ? `review {
          rating_summary
          reviews_count
        }` : ''}
        small_image {
          url(width: ${features.imageSize.product.width}, height: ${features.imageSize.product.height}),
          label
          url_original
        }
        categories {
          name
        }
        __typename
        price_tiers {
          discount {
            percent_off
            amount_off
          }
          final_price {
            currency
            value
          }
          quantity
        }
        price_range {
          maximum_price {
            discount{
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            discount{
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }

        special_from_date
        special_to_date
        new_from_date
        new_to_date
        ${modules.catalog.productListing.label.sale.enabled ? 'sale' : ''}
        ${modules.catalog.productListing.configurableOptions.enabled ? `
        ... on ConfigurableProduct {
          configurable_options {
            id
            attribute_id
            label
            attribute_code
            values {
              value_index
              label
              swatch_data {
                value
                ... on ImageSwatchData {
                  thumbnail
                  value
                }
                ... on ColorSwatchData {
                  value
                }
                ... on TextSwatchData {
                  value
                }
              }
            }
            product_id
          }
          variants {
            attributes {
              code
              label
              value_index
            }
            product {
              id
              sku
              stock_status
              ${modules.catalog.productListing.rating
        ? `review {
                rating_summary
                reviews_count
              }`
        : ''}
              price_tiers {
                discount {
                  percent_off
                  amount_off
                }
                final_price {
                  currency
                  value
                }
                quantity
              }
              price_range {
                maximum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
                minimum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
              }
              special_from_date
              special_to_date
              new_from_date
              new_to_date
              ${modules.catalog.productListing.label.sale.enabled ? 'sale' : ''}
              small_image{
                url(width: ${features.imageSize.product.width}, height: ${features.imageSize.product.height}),
                label
              }
              image {
                url
                label
              }
            }
          }
        }
        ` : ''}
      }
    }
  }
`;

export const addWishlist = gql`
    mutation addWishlist($productId: Int!) {
        addProductToWishlist(productId: $productId) {
            info
        }
    }
`;

export const addCompare = gql`
    mutation addCompare($productId: Int!, $vendorId: Int!, $uid: String) {
      setProductCompare(input: {product_id: $productId, vendor_id: $vendorId, uid: $uid}){
        status
      }
    }
`;

export const removeCompare = gql`
  mutation($compareItemId: Int!, $uid: String) {
    removeProductCompare(catalog_compare_item_id: $compareItemId, uid: $uid){
      status
    }
  }
`;

export const getCompareList = (vendorId) => gql`
query getProductCompareList($uid: String){
  getProductCompareList(uid: $uid){
    catalog_compare_item_id
    product_id
    product{
      sku
      name
      brand
      url_key
      small_image{
        url
        label
        url_original
      }
      description {
        html
      }
      short_description {
        html
      }
      more_info {
        label
        value
      }
      price_range {
        minimum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            amount {
              currency
              value
            }
            label
          }
          regular_price {
            currency
            value
          }
        }
        maximum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            amount {
              currency
              value
            }
            label
          }
          regular_price {
            currency
            value
          }
        }
      }
      ${vendorProductData(vendorId)}
    }
  }
}
`;
