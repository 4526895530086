/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import Button from '@common_button';
import { setVendor } from '@helper_cookies';
import useStyles from './style';

const StoresDialog = (props) => {
    const classes = useStyles();
    const {
        t,
        onClose,
        open,
        availableSource = [],
        isLayananInstalasi,
        setSelectedVendorById,
    } = props;

    const handleShopHere = (id) => {
        setSelectedVendorById(id);
        setVendor(id);
        onClose();
        if (typeof window !== 'undefined') {
            window.location.reload();
        }
    };

    return (
        <Dialog onClose={onClose} aria-labelledby="stores-dialog-title" open={open}>
            <DialogTitle id="stores-dialog-title">
                {t('product:findAStore')}
                <CloseIcon
                    className={classes.closeIcon}
                    onClick={onClose}
                />
            </DialogTitle>
            <DialogContent dividers>
                {availableSource.map((data, i) => (
                    <div key={i} className={classNames(classes.item, 'row')}>
                        <div className={classNames(classes.itemLeft, 'col-xs-12 col-sm-9')}>
                            <div>{data.vendor_name}</div>
                            <div className={classes.street}>{data.street}</div>
                        </div>
                        <div className={classNames(classes.itemRight, 'col-xs-12 col-sm-3')}>
                            <Button
                                className={classes.buttonShopHere}
                                color="primary"
                                onClick={() => handleShopHere(data.vendor_id)}
                            >
                                {t('product:shopHere')}
                            </Button>
                            {!isLayananInstalasi && (
                                <div className={classes.avaialable}>{data.stock_qty || 0} {t('product:avaialable')}</div>
                            )}
                        </div>
                    </div>
                ))}
            </DialogContent>
        </Dialog>
    );
};

export default StoresDialog;
