const { pimEndPoint } = require('@root/swift.config');

const generatePimUrl = (file_path) => {
    const imageUrl = `${pimEndPoint[process.env.APP_ENV] || pimEndPoint.dev}${file_path}`;
    return imageUrl;
};

module.exports = {
    generatePimUrl,
};
