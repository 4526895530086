/* eslint-disable max-len */
import Layout from '@layout';
import { StripHtmlTags } from '@helper_text';
import generateSchemaOrg from '@core_modules/catalog/helpers/schema.org';
import { getMonth, getYear } from '@root/core/helpers/date';
import { getCategory } from '../../services/graphql';

const Page = (props) => {
    const {
        Content, categoryId, storeConfig, SkeletonView, pageConfig = {}, ErrorView, ...other
    } = props;
    const { loading, data } = getCategory({
        productSize: 10,
        id: categoryId,
    });
    const ogContent = {};
    let config = {};
    let schemaOrg = null;
    if (data && data.categoryList[0]) {
        const category = data.categoryList[0];
        const metaTitle = `Jual ${data?.categoryList[0]?.name} Original dan Berkualitas | Mitra10 ${getMonth()} ${getYear()}`;
        schemaOrg = generateSchemaOrg(category, storeConfig);
        if (data.categoryList[0].description) {
            ogContent.description = StripHtmlTags(data.categoryList[0].description);
        }
        config = {
            title: loading ? '' : `PRODUK-${data?.categoryList[0].name}`,
            headerTitle: data && data?.categoryList[0].name,
            header: 'relative', // available values: "absolute", "relative", false (default)
            bottomNav: 'browse',
            pageType: 'category',
            metaTitle: data && data?.categoryList[0]?.meta_title ? `${data.categoryList[0].meta_title} | Mitra10 ${getMonth()} ${getYear()}` : metaTitle,
            keywords: data && data.categoryList[0] ? data.categoryList[0].meta_keywords : '',
            description: `Beli ${data?.categoryList[0]?.name} Original dan Resmi di Mitra10. Jasa Instalasi ✓ Cicilan 0% ✓ Garansi Servis ✓ Gratis Ongkir ✓ Nikmati juga Keuntungan Jadi Member Mitra10 Belanja Perabot Rumah dan Bangunan Aman di Mitra10.`,
            ogContent,
            schemaOrg,
            canonical: data?.categoryList[0]?.seo_canonical || '',
        };
    }
    if (loading && !data) {
        return (
            <Layout {...props} pageConfig={config}>
                <SkeletonView />
            </Layout>
        );
    }

    if (data && !data.categoryList[0]) {
        return <ErrorView statusCode={404} {...props} />;
    }
    return (
        <Layout {...props} pageConfig={config || pageConfig}>
            <Content categoryId={categoryId} storeConfig={storeConfig} data={data} {...other} />
        </Layout>
    );
};

export default Page;
