import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    CreatePadding, FlexColumn, Centering, CreateMargin, ClearMarginPadding, FlexRow,
} from '@theme_mixins';
import { GRAY_PRIMARY, PRIMARY } from '@theme_color';

export default makeStyles((theme) => ({
    container: {},
    itemContainer: {
        width: '100%',
        display: 'inline-block',
        height: '100%',
        overflow: 'hidden',
        ...CreatePadding(10, 10, 0, 10),
        ...CreateMargin(0, 0, 0, 0),
        position: 'relative',
        borderWidth: '0px 1px',
        '& .MuiSkeleton-rect': {
            paddingBottom: '120%',
        },
        '& a': {
            cursor: 'pointer',
        },
        '@media (min-width: 768px )': {
            ...CreatePadding(10, 10, 10, 10),
        },
    },
    badgesNewSales: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 5,
        borderRadius: 5,
        zIndex: 1,
        ...FlexRow,
        justifyContent: 'space-between',
        width: '100%',
        padding: 15,
    },
    imgItem: {
        width: '100%',
        ...Centering,
        border: '1px solid #DDDEE1',
        borderRadius: '8px',
    },
    imgProduct: {
        width: '100%',
        height: '100%',
        borderRadius: '8px',
    },
    detailItem: {
        backgroundColor: '#ffff',
        minHeight: 110,
        height: 'auto',
        padding: 8,
        position: 'relative',
        margin: 0,
    },
    descItem: {
        ...FlexColumn,
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        minHeight: 90,
        // maxWidth: '80%',
        // position: 'relative',
        '& .badge__container': {
            top: '50%',
            transform: 'translateY(-50%)',
            width: '38px',
            height: '38px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .badge__value': {
                fontSize: '16px',
                fontFamily: 'HartWell',
                [theme.breakpoints.down('xs')]: {
                    fontSize: '10px',
                },
            },
            [theme.breakpoints.down('xs')]: {
                right: '-10px',
                width: '24px',
                height: '24px',
            },
        },
        '& > div:first-of-type': {
            '& span': {
                fontSize: '20px',
                lineHeight: '27px',
            },
        },

        '& .price__final': {
            fontSize: '20px',
            lineHeight: '27px',
            fontFamily: 'HartWell',
        },
        '& strike': {
            fontWeight: 'bold',
            color: '#C7C7C7',
            fontSize: '16px',
            fontFamily: 'HartWell',
        },
    },
    productTitle: {
        ...CreateMargin(0, 0, 5, 0),
        maxHeight: 33,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '16px',
        fontFamily: 'HartWell',
    },
    btnFeed: {
        ...ClearMarginPadding,
        width: 20,
        height: 20,
        position: 'absolute',
        bottom: '0px',
        right: 25,
        textAlign: 'right',
        display: 'none',
        '@media (min-width: 768px )': {
            display: 'unset',
        },
    },
    btnCompare: {
        ...ClearMarginPadding,
        width: 20,
        height: 20,
        position: 'absolute',
        bottom: '0px',
        right: -10,
        textAlign: 'right',
        display: 'none',
        '@media (min-width: 768px )': {
            display: 'unset',
        },
    },
    productLinkButton: {
        maxWidth: '100%',
        '@media (min-width: 768px )': {
            maxWidth: 'calc(100% - 38px)',
        },
    },
    icon: {
        fontSize: 18,
        color: GRAY_PRIMARY,
        fontWeight: '200',
    },
    iconActive: {
        color: PRIMARY,
    },
    colorContainer: {
        ...FlexRow,
        ...CreatePadding(10, 10, 0, 0),
    },
    btnColor: {
        ...CreateMargin(0, 5, 0, 0),
    },
    feedContainer: {
        position: 'absolute',
        width: '20px',
        top: '-4px',
        right: '45px',
        textAlign: 'right',
    },
    linkProduct: {
        width: '100%',
    },
}));
