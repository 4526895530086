import classNames from 'classnames';
// import NextImage from 'next/image';
import React from 'react';
import { getImageFallbackUrl } from '@helpers_src/image';
import useStyles from './style';

const Image = ({
    src, width = 500, height = 500, className = '', alt = 'Image', lazy = true, ...other
}) => {
    const styles = useStyles();
    const onLoad = React.useCallback((event) => {
        event.target.classList.add('loaded');
    }, []);

    const onError = React.useCallback((event) => {
        event.target.classList.add('has-error');
    }, []);
    return (
        <div
            className={styles.thumborContainer}
        >
            <picture>
                <source
                    srcSet={`https://thumbor.sirclocdn.com/unsafe/${width}x${height}/filters:format(webp)/${src}`}
                    type="image/webp"
                />
                <source
                    srcSet={getImageFallbackUrl(`https://thumbor.sirclocdn.com/unsafe/${width}x${height}/filters:format(webp)/${src}`)}
                    type="image/jpeg"
                />
                <img
                    data-pagespeed-no-defer
                    className={classNames(styles.thumborImage, className, 'img')}
                    src={`https://thumbor.sirclocdn.com/unsafe/${width}x${height}/filters:format(webp)/${src}`}
                    alt={alt}
                    onLoad={lazy ? onLoad : null}
                    onError={lazy ? onError : null}
                    width={width}
                    height={height}
                    {...other}
                />
                <style jsx>
                    {`
                        // Add a smooth animation on loading
                        @keyframes loaded {
                            0% {
                            opacity: 0.1;
                            }
                            100% {
                            opacity: 1;
                            }
                        }
    
                        // I use utilitary classes instead of props to avoid style regenerating
                        img.loaded:not(.has-error) {
                            animation: loaded 300ms ease-in-out;
                        }
    
                        img.has-error {
                            // fallback to placeholder image on error
                            content: url(/assets/img/placeholder.png);
                        }
                    `}
                </style>
            </picture>
        </div>
    );
};

export default Image;
