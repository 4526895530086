import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
    container: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: 960,
        },
        width: ' 100%',
        alignSelf: 'center',
    },
    iframeContainer: {
        // border: '2px solid red',
    },
    iframeContent: {
        width: '100%',
        height: '600px',
    },
    pageTitle: {
        marginBottom: 0,
    },
    buttonInstalation: {
        marginTop: '16px',
        color: '#2E7BBF',
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '20px',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));
