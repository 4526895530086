import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { getInstallationGuide } from '@modules/product/services/graphql/index';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { generatePimUrl } from '@root/src/helpers/pimConfig';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Header from '@common_headermobile';
import useStyles from './style';

const InstallationPopup = (props) => {
    const {
        sku,
        setSniNumber,
        setK3lNumber,
    } = props;

    const styles = useStyles();
    const [open, setOpen] = useState(false);
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const handleCloseDialog = () => {
        setOpen(false);
    };

    const headerConfig = {
        headerTitle: 'Installation Guide',
        header: 'relative',
        headerBackIcon: 'close',
    };

    const { data, loading, error } = getInstallationGuide({
        variables: {
            sku,
        },
        skip: !sku,
    });

    useEffect(() => {
        if (data?.getAttrPim) {
            const sniNumber = data?.getAttrPim?.sni_no || '';
            const k3l_no = data?.getAttrPim?.k3l_no || '';
            setSniNumber(sniNumber);
            setK3lNumber(k3l_no);
        }
    }, [data]);

    if (loading || error || !data?.getAttrPim?.installation_guide) return null;

    return (
        <>
            <button
                type="button"
                onClick={() => setOpen(true)}
                className={styles.buttonInstalation}
            >
                <LibraryBooksIcon />
                <span>Installation Guide</span>
            </button>
            <Dialog
                open={open}
                maxWidth="sm"
                fullWidth={!!isDesktop}
                fullScreen={!isDesktop}
            >
                <div className={styles.container}>
                    <div>
                        <Header
                            pageConfig={headerConfig}
                            LeftComponent={{
                                onClick: () => {
                                    handleCloseDialog();
                                },
                            }}
                            className={styles.pageTitle}
                        />
                    </div>
                    <div className={styles.iframeContainer}>
                        <iframe
                            className={styles.iframeContent}
                            title="installation-service"
                            src={generatePimUrl(data?.getAttrPim?.installation_guide)}
                            // src="https://pdfobject.com/pdf/sample.pdf"
                        />
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default InstallationPopup;
