import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    GRAY_PRIMARY, PRIMARY, WHITE, GRAY_SECONDARY, BLUE_LIGHT,
} from '@theme_color';
import {
    CenterAbsolute, CreateBorder, CreateMargin, CreatePadding, FlexColumn, FlexRow, Centering,
} from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '0 !important',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            ...FlexColumn,
        },
        position: 'relative',
    },
    headContainer: {
        position: 'relative',
        backgroundColor: WHITE,
        width: '100%',
        // height: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        },
        // [theme.breakpoints.up('sm')]: {
        //     '& .slick-slider': {
        //         maxHeight: 420,
        //     },
        //     '& .slick-slide img': {
        //         maxHeight: 450,
        //     },
        // },
        // '& > div:first-child > div:first-child': {
        //     overflow: 'auto',
        //     maxHeight: 450,
        //     paddingRight: 12,
        // },
    },
    header: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 3,
    },

    body: {
        ...CreatePadding(15, 30, 30, 30),
        ...FlexColumn,
        '@media (max-width: 767px )': {
            ...CreatePadding(15, 0, 30, 0),
        },
    },

    footer: {
        ...FlexRow,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 55,
        left: 0,
        ...CenterAbsolute,
        background: 'rgba(255,255,255,1)',
        // opacity : 0.7,
        ...CreatePadding(20, 20, 20, 20),
        zIndex: theme.zIndex.drawer + 1,
    },

    title: {
        [theme.breakpoints.up('sm')]: {
            fontSize: 34,
            lineHeight: '42px',
            fontFamily: 'Montserrat',
        },
        '@media (max-width: 767px )': {
            fontSize: '20px',
            lineHeight: '26px',
            marginBottom: '10px !important',
        },
    },

    btnAddToCard: {
        width: '90%',
        [theme.breakpoints.up('sm')]: {
            width: 316,
        },
        height: 41,
        bottom: 0,
        left: 0,
        opacity: 'none',
        color: WHITE,
        borderRadius: 100,
        backgroundColor: BLUE_LIGHT,
    },

    textBtnAddToCard: {
        fontSize: 16,
        color: `${WHITE} !important`,
    },

    titleContainer: {
        ...FlexRow,
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    titlePriceContainer: {
        ...FlexColumn,
        flex: 1,
        fontSize: 20,
        marginBottom: '20px',
        '& .price_text': {
            fontSize: 34,
            fontFamily: 'HartWell',
        },
        '& .price__old': {
            fontSize: 16,
            // color: '#757575',
            color: '#C7C7C7',
            display: 'block',
            fontFamily: 'HartWell',
        },
        '& .price__final': {
            fontSize: 34,
            display: 'block',
            lineHeight: '30px',
            fontFamily: 'HartWell',
        },
        '& .priceShowDisccount': {
            display: 'flex',
            fontFamily: 'HartWell',
            position: 'relative',
            alignItems: 'center',
            width: '100%',
            flexDirection: 'row !important',
            '@media (max-width: 767px )': {
                '& .price__old, .price__final': {
                    fontSize: 10,
                },
            },
        },
        '& .badge__container': {
            position: 'relative',
            top: 0,
            borderRadius: '50px',
            textAlign: 'center',
            height: '48px !important',
            width: '48px !important',
        },
        '& .badge__value': {
            lineHeight: '50px !important',
            color: '#fff',
            fontSize: '17px !important',
            fontFamily: 'HartWell',
        },
        [theme.breakpoints.down('sm')]: {
            '& .price_text': {
                fontSize: 18,
            },
        },
        '@media (max-width: 767px )': {
            '& .price__old': {
                fontSize: '15px !important',
            },
            '& .price__final': {
                fontSize: '20px !important',
            },
            '& .badge__container': {
                top: '50%',
                transform: 'translateY(-50%)',
                width: '35px',
                height: '35px',
                position: 'absolute',
                right: 0,
            },
            '& .badge__value': {
                fontSize: '14px !important',
                lineHeight: '50px !important',
            },
        },
    },

    shareContainer: {
        ...FlexRow,
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        marginTop: 15,
        '& .desc': {
            fontSize: 14,
            textTransform: 'uppercase',
            fontWeight: 'bold',
            marginRight: 24,
            cursor: 'pointer',
        },
        '& .item-share': {
            marginRight: 12,
        },
        '@media (max-width: 767px )': {
            display: 'block',
            position: 'absolute',
            top: '40px',
            right: '-10px',
            '& button span': {
                fontSize: 9,
            },
            '& .desc': {
                fontSize: 12,
                marginRight: 0,
                cursor: 'pointer',
            },
        },
        '@media (min-width: 767px )': {
            marginLeft: '15%',
        },
    },

    shareRootContainer: {
        ...CreatePadding(15, 30, 30, 30),
        ...FlexColumn,
        ...CreateBorder(0, 0, '1px', 0, GRAY_PRIMARY),
        [theme.breakpoints.down('sm')]: {
            marginBottom: 40,
        },
    },

    btnShare: {
        margin: '0px !important',
        padding: '10px',
        '@media (max-width: 767px )': {
            padding: '7px 10px',
        },
    },

    iconShare: {
        color: PRIMARY,
        fontSize: 25,
        ...CreateMargin(0, 0, 0, 0),
        '@media (max-width: 767px )': {
            fontSize: 20,
        },
    },

    carouselContainer: {
        paddingTop: 40,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 70,
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    carouselTitle: {
        marginBottom: '20px',
    },
    desc: {
        ...CreateMargin(12, 0, 12, 0),
        textAlign: 'center',
    },
    ratingContainer: {
        ...FlexRow,
        marginBottom: 7,
    },
    bottom20: {
        [theme.breakpoints.up('sm')]: {
            marginBottom: 20,
        },
    },
    btnLoadReview: {
        ...Centering,
        textAlign: 'center',
    },
    textLoadReview: {
        color: `${GRAY_SECONDARY} !important`,
    },
    sku: {
        alignItems: 'center',
        marginLeft: '0 !important',
        marginBottom: 8,
        '& p': {
            fontSize: 20,
        },
        '@media (max-width: 767px )': {
            marginTop: 0,
        },
    },
    tabs: {
        paddingTop: '40px',
    },
    shareTitle: {
        marginTop: 20,
        fontSize: 12,
    },
    titleRiviews: {
        fontSize: 20,
    },
    threedyContainer: {
        marginTop: 30,
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '13%',
            '& > div': {
                textAlign: 'right',
            },
            '& div:nth-child(2)': {
                textAlign: 'left',
                paddingLeft: '20px',
            },
        },
    },
    btnThreedy: {
        width: 'fit-content',
        height: 41,
        bottom: 0,
        left: 0,
        opacity: 'none',
        color: WHITE,
        borderRadius: 100,
        backgroundColor: BLUE_LIGHT,
    },
    badgeInfo: {
        backgroundColor: '#fff3cd',
        display: 'inline-block',
        margin: '5px 0',
        padding: '4px 8px',
        textAlign: 'center',
        borderRadius: 6,

        '& p': {
            color: '#856404',
            fontSize: '12px',
        },
    },
    inStock: {
        color: '#15B722',
        fontSize: 15,
    },
    outOfStock: {
        color: '#FF1726',
        fontSize: 12,
    },
    reviews: {
        color: '#757575',
        fontSize: 13,
        margin: '5px 0',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .item-share': {
            padding: '20px',
            backgroundColor: '#ffffff',
        },
        '& button:not(:last-child)': {
            marginRight: '15px',
        },
    },
    btnCancel: {
        marginTop: '20px',
        backgroundColor: '#2E3092',
        borderRadius: 5,
    },
    triRight: {
        textAlign: 'right',
    },
    width100: {
        width: '100%',
        marginRight: 0,
        marginLeft: 0,
        alignItems: 'center',
    },
    noPadding: {
        padding: 0,
    },
    shortDesc: {
        marginTop: '20px',
    },
    preorderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    preorderText: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#f32e3b',
        '& img': {
            height: '54px',
            width: '54px',
        },
    },
    titleCategory: {
        marginBottom: '15px !important',
        fontSize: '20px',
        lineHeight: '18px',
        display: 'block',
    },
    compareWrapper: {
        textAlign: 'center',
    },
}));

export default useStyles;
