import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_BACKGROUND } from '@theme_color';

const useStyles = makeStyles({
    closeIcon: {
        fontSize: 27,
        float: 'right',
        cursor: 'pointer',
    },
    item: {
        padding: 12,
        border: 0,
        borderBottom: `1px solid ${GRAY_BACKGROUND}`,
    },
    itemLeft: {
        marginBottom: 12,
    },
    itemRight: {
        '& > div': {
            textAlign: 'right',
        },
    },
    street: {
        fontSize: 12,
    },
    buttonShopHere: {
        borderRadius: 4,
        textTransform: 'capitalize',
        width: '100%',
        maxWidth: 200,
        marginBottom: 8,
    },
    avaialable: {
        textAlign: 'right',
    },
});

export default useStyles;
