/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import classNames from 'classnames';
// import Button from '@material-ui/core/Button';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import useStyles from './style';
// import Typography from '@common_typography';

const ShippingMethodAndStore = (props) => {
    const {
        t,
        product,
        STORE_PICKUP,
        DELIVERY,
        selectedVendor,
        setSelectedVendorById,
        selectedShippingMethod,
        setSelectedShippingMethod,
        availableSource,
        StoresDialog,
    } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const getBoxClass = (value) => (value === selectedShippingMethod ? 'selected' : '');
    // const getAvailableSourceNumber = () => (availableSource ? availableSource.length : 0);

    return (
        <div className={classes.root}>
            <StoresDialog
                open={open}
                onClose={() => setOpen(false)}
                isLayananInstalasi={product?.layanan_instalasi}
                availableSource={availableSource}
                t={t}
                setSelectedVendorById={setSelectedVendorById}
            />
            <div>{product?.layanan_instalasi ? t('product:selectShippingAndStoreLayanan') : t('product:selectShippingAndStore')}</div>
            <div className="row">
                {!product?.layanan_instalasi && (
                    <div className={classNames('col-xs-12 col-sm-6', classes.boxWrapper)}>
                        <div
                            className={classNames(classes.box, getBoxClass(STORE_PICKUP))}
                            onClick={() => setSelectedShippingMethod(STORE_PICKUP)}
                        >
                            <div className={classes.top}>
                                <div className={classes.imgContainer}>
                                    {selectedShippingMethod === STORE_PICKUP
                                        ? <img src="/assets/img/pdp/store_pickup_white.svg" alt="" />

                                        : <img src="/assets/img/store_pickup.png" alt="" />}
                                </div>
                                <div className={classes.topDesc}>
                                    <div className={classes.shippingMethod}>
                                        {t('product:clickAndCollect')}
                                    </div>
                                    <div className={classNames('selectedWrapper', classes.vendorName)}>
                                        {selectedVendor && selectedVendor.label}
                                    </div>
                                </div>
                            </div>
                            {/* <div className={classes.available}>
                                <CheckCircleIcon className="checkIcon" />
                                <Typography className="vendorAvailable" variant="span" align="center" letter="lowercase" color="black" size="12">
                                    {`${getAvailableSourceNumber()} ${t('product:availableForClickAndCollect')}`}
                                </Typography>
                            </div> */}
                            {/* <Button
                                className={classes.button}
                                variant="outlined"
                                onClick={() => setOpen(true)}
                            >
                                {t('product:checkOtherStores')}
                            </Button> */}
                        </div>
                    </div>
                )}
                <div className={classNames('col-xs-12 col-sm-6', classes.boxWrapper)}>
                    <div
                        className={classNames(classes.box, getBoxClass(DELIVERY))}
                        onClick={() => setSelectedShippingMethod(DELIVERY)}
                    >
                        <div className={classes.top}>
                            <div className={classes.imgContainer}>
                                {selectedShippingMethod === DELIVERY
                                    ? <img src="/assets/img/pdp/truck_active.png" alt="" />
                                    : <img src="/assets/img/pdp/truck.svg" alt="" /> }
                            </div>
                            <div className={classes.topDesc}>
                                <div className={classes.shippingMethod}>
                                    {product?.layanan_instalasi ? t('product:deliveryToHomeLayanan') : t('product:deliveryToHome')}
                                </div>
                                <div className={classNames('selectedWrapper', classes.vendorName)}>
                                    {selectedVendor && selectedVendor.label}
                                </div>
                            </div>
                        </div>
                        {/* <div className={classes.available}>
                            <CheckCircleIcon className="checkIcon" />
                            <Typography className="vendorAvailable" variant="span" align="center" letter="lowercase" color="black" size="12">
                                {`${getAvailableSourceNumber()} ${product?.layanan_instalasi
                                    ? t('product:availableForDeliveryLayanan')
                                    : t('product:availableForDelivery')}`}
                            </Typography>
                        </div> */}
                        {/* <Button
                            className={classes.button}
                            variant="outlined"
                            onClick={() => setOpen(true)}
                        >
                            {t('product:checkOtherStores')}
                        </Button> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShippingMethodAndStore;
