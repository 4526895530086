import Core from './core';
import DefaultView from './view/default';

const FlashSaleTimer = (props) => {
    const { View, ...others } = props;
    const Content = View || DefaultView;

    return (<Core View={Content} {...others} />);
};

export default FlashSaleTimer;
