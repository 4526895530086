/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable react/no-danger */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-duplicates */
import Button from '@common_button';
import PriceFormat from '@common_priceformat';
import Banner from '../../../../commons/Slick/BannerThumbnail';
import BannerMobile from '../../../../commons/Slick/BannerThumbnail';
// import Caraousel from '@common_slick/Caraousel';
// import CarouselSkeleton from '@common_slick/Caraousel/Skeleton';
import Typography from '@common_typography';
import IconButton from '@material-ui/core/IconButton';
// import Favorite from '@material-ui/icons/Favorite';
// import FavoriteBorderOutlined from '@material-ui/icons/FavoriteBorderOutlined';
// import ShareOutlined from '@material-ui/icons/ShareOutlined';
// import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import classNames from 'classnames';
import ProductItem from '@core_modules/catalog/plugin/ProductItem';
import React, { useEffect } from 'react';
import { getHost } from '@helper_config';
import { getVendor as getVendorCookies } from '@helper_cookies';
// import Breadcrumb from '@common_breadcrumb';
import FlashSaleTimer from '@common_flashsaletimer';
import FlashSaleTimerView from '@common_flashsaletimer/view/simplePdp';
// import RatingStar from '@common_ratingstar';
import { breakPointsUp } from '@helper_theme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import dynamic from 'next/dynamic';
import useStyles from './style';
import ExpandDetail from '@modules/product/pages/default/components/ExpandDetail';
import ListReviewsMobile from '@modules/product/pages/default/components/ListReviewsMobile';
import OptionItem from '@modules/product/pages/default/components/OptionItem';
import RightDrawer from '@core_modules/product/pages/default/components/RightDrawer';
import SharePopup from '@modules/product/pages/default/components/SharePopup';
import ModalPopupImage from '@modules/product/pages/default/components/ModalPopupImage';
import ShippingMethodAndStore from './shippingMethodAndStore';
// import Modal from '@material-ui/core/Modal';
// import Backdrop from '@material-ui/core/Backdrop';
// import Fade from '@material-ui/core/Fade';

const DesktopOptions = dynamic(() => import('@modules/product/pages/default/components/OptionItem/DesktopOptions'), { ssr: true });
const TabsView = dynamic(() => import('./DesktopTabs'), { ssr: true });
// const ItemShare = dynamic(() => import('@modules/product/pages/default/components/SharePopup/item'), { ssr: true });
const Caraousel = dynamic(() => import('@common_slick/Caraousel'), { ssr: false });
// const XenditCalculator = dynamic(() => import('./XenditCalculator'), { ssr: true });

const ProductPage = (props) => {
    const styles = useStyles();
    const {
        t,
        data,
        getAvailableSource,
        openOption,
        handleOption,
        setOpenOption,
        setBanner,
        setPrice,
        openShare,
        setOpenShare,
        route,
        banner,
        openDrawer,
        setOpenDrawer,
        // breadcrumbsData,
        price,
        // handleWishlist,
        handleCompare,
        // reviewValue,
        // wishlist,
        expandData,
        relateData,
        openImageDetail,
        handleOpenImageDetail,
        // isLogin,
    } = props;
    const [flashSaleData] = data.vendor_product_data;
    const startFlashSaleDate = new Date(flashSaleData.flash_sale_from).getTime();
    const endFlashSaleDate = new Date(flashSaleData.flash_sale_to).getTime();
    const isFlashSale = Date.now() > startFlashSaleDate && Date.now() < endFlashSaleDate && flashSaleData.show_countdown;
    const desktop = breakPointsUp('sm');
    const isIpad = useMediaQuery('(max-width: 912px)');

    // const favoritIcon = wishlist ? <Favorite className={styles.iconShare} /> : <FavoriteBorderOutlined className={styles.iconShare} />;
    const availableSource = getAvailableSource.data && getAvailableSource.data.getAvailableSource && getAvailableSource.data.getAvailableSource.list;
    const getStockVendor = () => {
        if (availableSource && availableSource.length) {
            const obj = availableSource.find((e) => e.vendor_id === getVendorCookies());
            return (obj && obj.stock_qty) || 0;
        }
        return 0;
    };

    let contentCaraousel = '';
    if (typeof window !== 'undefined' && relateData.length > 0) {
        contentCaraousel = <Caraousel data={relateData} Item={ProductItem} />;
    }

    const getPreorder = () => data && data.vendor_product_data && data.vendor_product_data[0] && data.vendor_product_data[0].preorder > 0;

    const getPreorderTime = () => {
        const tmp = data && data.vendor_product_data && data.vendor_product_data[0];
        if (tmp && tmp.preorder_time) {
            return tmp.preorder_time;
        }
        if (tmp && tmp.preorder_time_config) {
            return tmp.preorder_time_config;
        }
        return '7';
    };

    React.useEffect(() => {
        const script = document.createElement('script');
        const script2 = document.createElement('script');

        script.type = 'text/javascript';
        script.dangerouslySetInnerHTML = {
            __html: 'var QUICK_AR_INIT = {key : "4LytRhdbKD"}',
        };
        script.async = true;
        script2.src = 'https://cdn-quick-ar.threedy.ai/latest/threedy.js';
        document.body.appendChild(script);
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script);
            document.body.removeChild(script2);
        };
    }, []);

    // modal share
    const [openModal, setOpenModal] = React.useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <div style={{
            width: '100%',
            paddingBottom: '100px',
            // minHeight: 'calc(100vh - 500px)',
        }}
        >
            <div className="hidden-mobile">
                {data && data.upsell_products && data.upsell_products.length > 0 && (
                    <RightDrawer open={openDrawer} setOpen={() => setOpenDrawer(!openDrawer)} {...props} />
                )}
                <ModalPopupImage open={openImageDetail} setOpen={handleOpenImageDetail} banner={banner} />
            </div>
            <OptionItem {...props} open={openOption} setOpen={() => setOpenOption(!openOption)} setBanner={setBanner} setPrice={setPrice} />
            <SharePopup open={openShare} setOpen={() => setOpenShare(!openShare)} link={getHost() + route.asPath} {...props} />
            <div className={classNames(styles.container, 'row')}>
                {/* <div className="col-lg-12 hidden-mobile">
                    <Breadcrumb data={breadcrumbsData} variant="text" />
                </div> */}
                <div className={classNames(styles.headContainer, 'col-xs-12 col-lg-6')}>
                    {desktop ? (
                        <Banner
                            data={banner}
                            noLink
                            thumbnail
                            showArrow={false}
                            contentWidth="auto"
                            autoPlay={false}
                            width={420}
                            height={420}
                            actionImage={desktop ? handleOpenImageDetail : () => {}}
                        />
                    ) : (
                        <>
                            {/* <div style={{ marginTop: '16%' }} /> */}
                            <BannerMobile
                                data={banner}
                                noLink
                                thumbnail
                                showArrow={false}
                                contentWidth="auto"
                                autoPlay={false}
                                width={420}
                                height={420}
                                actionImage={desktop ? handleOpenImageDetail : () => {}}
                            />
                        </>
                    )}
                    <div data-threedy-web-id={data.sku} className={styles.threedyContainer}>
                        <Button className={classNames('hidden hide threedy-3d-btn', styles.btnThreedy)} color="primary">
                            <Typography variant="span" align="center" type="bold" letter="uppercase" color="white" size="10">
                                {t('product:seeIn3D')}
                            </Typography>
                        </Button>
                        <Button className={classNames('hidden hide threedy-qar-btn', styles.btnThreedy)} color="primary">
                            <Typography variant="span" align="center" type="bold" letter="uppercase" color="white" size="10">
                                {t('product:seeInSpace')}
                            </Typography>
                        </Button>
                    </div>
                    {/* <div className={styles.shareContainer}>
                        <IconButton className={styles.btnShare} onClick={handleWishlist}>
                            <img className="hidden-mobile" alt="wishlist" src="/assets/img/pdp/save.svg" />
                            <img className="hidden-desktop" alt="wishlist" src="/assets/img/pdp/save_mobile.svg" />
                        </IconButton>
                        <div className="desc hidden-mobile" onClick={handleWishlist}>
                            Save
                        </div>
                        <div className="hidden-desktop">
                            <IconButton className={classNames(styles.btnShare)} onClick={() => setOpenShare(true)}>
                                <img className="hidden-mobile" alt="share" src="/assets/img/pdp/share.svg" />
                                <img className="hidden-desktop" alt="wishlist" src="/assets/img/pdp/share_mobile.svg" />
                            </IconButton>
                        </div>
                        <div className="desc hidden-desktop hidden-mobile" onClick={() => setOpenShare(true)}>
                            {t('product:shareTitle')}
                        </div>
                        <div className="hidden-mobile">
                            <IconButton className={classNames(styles.btnShare)} onClick={handleOpenModal}>
                                <img alt="share" src="/assets/img/pdp/share.svg" />
                            </IconButton>
                        </div>
                        <div className="desc hidden-mobile" onClick={handleOpenModal}>
                            {t('product:shareTitle')}
                        </div>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classNames(styles.modal, 'hidden-mobile')}
                            open={openModal}
                            onClose={handleCloseModal}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={openModal}>
                                <div className="hidden-mobile item-share">
                                    <ItemShare link={getHost() + route.asPath} />
                                    <Button className={styles.btnCancel} color="secondary" onClick={handleCloseModal}>
                                        {t('common:button:cancel')}
                                    </Button>
                                </div>
                            </Fade>
                        </Modal>
                        <IconButton className={styles.btnShare} onClick={handleCompare}>
                            <img className="hidden-mobile" alt="compare" src="/assets/img/pdp/compare.svg" />
                            <img className="hidden-desktop" alt="wishlist" src="/assets/img/pdp/compare_mobile.svg" />
                        </IconButton>
                        <div className="desc hidden-mobile" onClick={handleCompare}>
                            Compare
                        </div>
                    </div> */}
                    {/* <div className="hidden-desktop">
                        {data && data.upsell_products && data.upsell_products.length > 0 && (
                            <RightDrawer open={openDrawer} setOpen={() => setOpenDrawer(!openDrawer)} {...props} />
                        )}
                    </div> */}
                </div>
                <div className={classNames(styles.body, 'col-xs-12 col-lg-6')}>
                    {/* <div className={classNames(styles.titleContainer, 'hidden-desktop')}>
                        <Breadcrumb data={breadcrumbsData} variant="text" />
                    </div> */}
                    {isFlashSale ? <FlashSaleTimer View={FlashSaleTimerView} flashSaleData={flashSaleData} /> : null}
                    {/* {
                        data.categories.length > 0 ? (
                            <Typography
                                variant="p"
                                type="bold"
                                letter="capitalize"
                                className={classNames(styles.titleCategory, 'clear-margin-padding')}
                            >
                                {data.categories[0].name}
                            </Typography>
                        ) : null
                    } */}
                    <div className={styles.titleContainer}>
                        <div className={styles.titlePriceContainer}>
                            <Typography variant="title" type="bold" letter="capitalize" className={classNames(styles.title, 'clear-margin-padding')}>
                                {data.name}
                            </Typography>
                        </div>
                    </div>

                    {!data?.layanan_instalasi && (
                        <div className={styles.titleContainer}>
                            <div className={classNames('row', styles.width100)}>
                                <div className={classNames(styles.noPadding, 'col-lg-6 col-md-6 col-sm-6 col-xs-6')}>
                                    <div className={classNames('row', styles.sku)}>
                                        <Typography className="clear-margin-padding" variant="p" type="regular" letter="capitalize">
                                            SKU#:
                                            {' '}
                                        </Typography>
                                        <Typography variant="p" type="bold" letter="none">
                                            {data.sku || ''}
                                        </Typography>
                                    </div>
                                </div>
                                {/* <div className={classNames(styles.noPadding, 'col-lg-6 col-md-6 col-sm-6 col-xs-6')}>
                                    {!getPreorder() && (
                                        <Typography
                                            variant="p"
                                            type="bold"
                                            align="right"
                                            letter="uppercase"
                                            className={getStockVendor() > 0 ? styles.inStock : styles.outOfStock}
                                        >
                                            {getStockVendor() > 0 ? 'in stock' : t('product:outStock')}
                                        </Typography>
                                    )}
                                </div> */}
                            </div>
                        </div>
                    )}

                    {/* <div className={styles.titleContainer}>
                        <div className={styles.ratingContainer}>
                            <RatingStar value={reviewValue || 5} sizeIcon="20" />
                            <Typography className={styles.reviews} variant="p" type="regular" letter="capitalize">
                                {data.review.reviews_count || 0}
                                {' '}
                                {t('product:review')}
                            </Typography>
                        </div>
                    </div> */}

                    <div className={styles.titleContainer}>
                        <div className={styles.titlePriceContainer}>
                            <PriceFormat {...price} />
                        </div>
                        <div className={styles.compareWrapper}>
                            <IconButton className={styles.btnShare} onClick={handleCompare}>
                                <img className="hidden-mobile" alt="compare" src="/assets/img/pdp/compare.svg" />
                                <img className="hidden-desktop" alt="wishlist" src="/assets/img/pdp/compare_mobile.svg" />
                            </IconButton>
                            <div className="desc" onClick={handleCompare}>
                                Bandingkan
                            </div>
                        </div>
                    </div>

                    {/* <div className={styles.titleContainer}>
                        <XenditCalculator t={t} product={data} price={price} isLogin={isLogin} />
                    </div> */}

                    {getPreorder() && (
                        <div className={styles.preorderContainer}>
                            <div className={classNames(styles.titleContainer, 'mt-4')}>
                                <div
                                    className={styles.preorderText}
                                >
                                    <img src="/assets/img/pre-order.png" alt="preorder" />
                                    <span>
                                        {`Pre-Order ${getPreorderTime()} ${t('common:product:day')}`}
                                    </span>
                                </div>
                            </div>
                            {/* <IconButton className={styles.btnShare} onClick={handleWishlist}>
                                <img className="hidden-mobile" alt="wishlist" src="/assets/img/pdp/save.svg" />
                                <img className="hidden-desktop" alt="wishlist" src="/assets/img/pdp/save_mobile.svg" />
                            </IconButton> */}
                            {/* <Typography variant="p" size="14" className="clear-margin-padding" letter="none">
                                {t('product:preOrder')}
                            </Typography> */}
                        </div>
                    )}
                    {/* {data.short_description.html
                        ? (
                            <div className={styles.shortDesc}>
                                <Typography variant="p" size="14" align="left" className="clear-margin-padding" letter="none">
                                    <div dangerouslySetInnerHTML={{ __html: data.short_description.html }} />
                                </Typography>
                            </div>
                        )
                        : null} */}

                    {data?.sku_sensitive && (
                        <div className={styles.titleContainer}>
                            <div className={styles.badgeInfo}>
                                <Typography variant="p" className="clear-margin-padding" type="bold" letter="none">
                                    {t('product:noPromo')}
                                </Typography>
                            </div>
                        </div>
                    )}

                    {data.product_voucher_code ? (
                        <div className={styles.titleContainer}>
                            <Typography variant="p" type="bold" letter="capitalize" color="red">
                                {data.product_voucher_code}
                            </Typography>
                        </div>
                    ) : (
                        <div className={styles.bottom20} />
                    )}

                    <ShippingMethodAndStore {...props} t={t} product={data} getAvailableSource={getAvailableSource} />
                    <div className="hidden-desktop">
                        <DesktopOptions
                            {...props}
                            setOpen={setOpenOption}
                            setBanner={setBanner}
                            setPrice={setPrice}
                            getStockVendor={getStockVendor}
                        />
                        <Typography variant="span" type="regular" size="12">
                            {data.short_description.html ? <span dangerouslySetInnerHTML={{ __html: data.short_description.html }} /> : null}
                        </Typography>
                    </div>
                    <div className="hidden-desktop">
                        {' '}
                        <div className={classNames('hidden-mobile', styles.desc)}>
                            <Typography variant="span" type="regular" size="12">
                                {data.short_description.html ? <span dangerouslySetInnerHTML={{ __html: data.short_description.html }} /> : null}
                            </Typography>
                        </div>
                        <div>
                            <ExpandDetail data={expandData} sku={data.sku} />
                        </div>
                    </div>
                    <div className="hidden-mobile">
                        <DesktopOptions
                            {...props}
                            setOpen={setOpenOption}
                            setBanner={setBanner}
                            setPrice={setPrice}
                            getStockVendor={getStockVendor}
                        />
                        <Typography variant="span" type="regular" size="12">
                            {data.short_description.html ? <span dangerouslySetInnerHTML={{ __html: data.short_description.html }} /> : null}
                        </Typography>
                    </div>
                </div>
                <div className="hidden-desktop">
                    <ListReviewsMobile {...props} />
                </div>
                <div className={classNames(styles.tabs, 'col-xs-12 col-lg-12 hidden-mobile')}>
                    {
                        isIpad ? (
                            <>
                                <ExpandDetail data={expandData} sku={data.sku} />
                                <ListReviewsMobile {...props} />
                            </>
                        ) : (
                            <TabsView {...props} dataInfo={expandData} />
                        )
                    }
                </div>
                {/* {relateData.length !== 0 ? (
                    <div className={classNames(styles.carouselContainer, 'col-xs-12 col-lg-12')}>
                        <Typography variant="h1" component="h2" align="center" className={styles.carouselTitle}>
                            {t('common:title:relatedProduct')}
                        </Typography>
                        {contentCaraousel}
                    </div>
                ) : null} */}

                {/* <div className={classNames(styles.footer, 'hidden-desktop')}>
                    {(getStockVendor() > 0 || getPreorder()) && (price?.vendorCost !== 0 || price?.vendorSpecialPrice !== 0) ? (
                        <Button className={styles.btnAddToCard} color="primary" onClick={handleOption}>
                            <Typography variant="span" align="center" type="bold" letter="uppercase" color="white">
                                {t('product:addToCart')}
                            </Typography>
                        </Button>
                    ) : (
                        <Typography align="center" type="semiBold" color="red" variant="subtitle2">
                            {t('product:productIsUnavailable')}
                        </Typography>
                    )}
                </div> */}
            </div>
            <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: 'var QUICK_AR_INIT = {key : "4LytRhdbKD"}',
                }}
            />
            <script src="https://cdn-quick-ar.threedy.ai/latest/threedy.js" defer />
        </div>
    );
};

export default ProductPage;
