/* eslint-disable arrow-body-style */
import React from 'react';
import Core from './core';
import Content from './view';
import StoresDialog from './components/storesDialog';

const ShippingMethodAndStore = (props) => {
    return (
        <Core
            {...props}
            Content={Content}
            StoresDialog={StoresDialog}
        />
    );
};

export default ShippingMethodAndStore;
