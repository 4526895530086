import React, { useEffect } from 'react';
import GridList from '@common_gridlist';
import Typography from '@common_typography';
import { modules } from '@config';
import Filter from '@modules/catalog/plugin/ProductList/components/Filter';
import ProductItem from '@modules/catalog/plugin/ProductItem/index';
import LabelView from '@core_modules/catalog/plugin/ProductItem/components/LabelView';
import { breakPointsUp } from '@root/core/helpers/theme';
import useStyles from './style';

const Content = (props) => {
    const styles = useStyles();
    const {
        query, showTabs, customFilter, elastic, t,
        aggregations, setFiltervalue, category, defaultSort, config, TabView,
        products, categoryPath, renderEmptyMessage, ProductListSkeleton, loading,
        loadmore, handleLoadMore, dataTabs, onChangeTabs, ...other
    } = props;

    const isLargeScreen = breakPointsUp(1440);

    const handleScroll = () => {
        // To get page offset of last user
        const lastUserLoaded = document.querySelector(
            '.grid-item:last-child',
        );
        if (lastUserLoaded) {
            const lastUserLoadedOffset = lastUserLoaded.offsetTop + lastUserLoaded.clientHeight;
            const pageOffset = window.pageYOffset + window.innerHeight;
            if (pageOffset > lastUserLoadedOffset && !loadmore && products.items.length < products.total_count) {
                handleLoadMore();
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    return (
        <div className={styles.containerWrap}>
            <div>
                <Filter
                    filter={customFilter || aggregations}
                    defaultSort={JSON.stringify(defaultSort)}
                    filterValue={query}
                    setFiltervalue={setFiltervalue}
                    isSearch={!!config.search}
                    products={products}
                    renderEmptyMessage={renderEmptyMessage}
                    loading={loading}
                    t={t}
                    {...other}
                />
            </div>

            <div className="row">
                <div className="col-sm-12 col-xs-12 col-lg-12">
                    <div className={styles.productContainer}>
                        {loading && <ProductListSkeleton />}
                        {!loading && (
                            <GridList
                                data={products.items}
                                ItemComponent={ProductItem}
                                className="grid"
                                itemProps={{
                                    categorySelect: categoryPath,
                                    LabelView,
                                    className: 'grid-item',
                                    ...other,
                                }}
                                gridContainerProps={{
                                    spacing: isLargeScreen ? 3 : 2,
                                }}
                                gridItemProps={{ xs: 6, sm: 4, md: modules.catalog.productListing.drawerFilterOnDesktop.enabled ? 3 : 2 }}
                            />
                        )}
                        {(products.items.length === products.total_count) || loading
                            ? (
                                <div style={{ marginTop: isLargeScreen ? '80px' : '40px' }}>
                                    {renderEmptyMessage(products.items.length, loading)}
                                </div>
                            )
                            : null}
                        {loadmore ? (
                            <div className={styles.divLoadMore}>
                                <Typography align="center" variant="span" type="bold" letter="uppercase" color="gray">
                                    Loading
                                </Typography>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Content;
