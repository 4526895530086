import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_PRIMARY, WHITE } from '@theme_color';
import { FlexColumn } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        ...FlexColumn,
    },
    headContainer: {
        [theme.breakpoints.up('sm')]: {
            position: 'relative',
            backgroundColor: GRAY_PRIMARY,
            marginLeft: '-2.55%',
            marginRight: '-2.55%',
        },
    },
    centered: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: WHITE,
        fontWeight: 600,
        fontSize: '38px',
        margin: 0,
        [theme.breakpoints.up('md')]: {
            fontSize: '46px',
        },
    },
    headContainerNoBanner: {
        backgroundColor: WHITE,
        height: '40vh',
    },
    header: {
        left: '50%',
        right: '50%',
        top: '11px',
        position: 'absolute',
        borderBottom: 'none',
        fontWeight: 'bold',
    },
    breadcrumbs: {
        padding: '16px 16px 0',
    },
    categoryName: {
        padding: '0px 16px 0',
        margin: 0,
    },
    seoFooter: {
        padding: '40px 0',
        '& h2': {
            textTransform: 'capitalize',
            fontWeight: 600,
        },
        '& p': {
            textAlign: 'justify',
            fontWeight: '300',
            lineHeight: '150%',
            fontSize: '14px',
            margin: 0,
        },
        [theme.breakpoints.down('sm')]: {
            padding: '40px 10px',
        },
    },
}));

export default useStyles;
