import { withApollo } from '@lib_apollo';
import { withTranslation } from '@i18n';
import CategoryPage from '@modules/catalog/pages/category';
import ProductPage from '@modules/product/pages/default';
import CmsPage from '@modules/cms/pages/default';
import ProductLoader from '@core_modules/product/pages/default/components/Loader';
import CategorySkeleton from '@core_modules/catalog/pages/category/components/Skeleton';
import { storeConfig as ConfigSchema } from '@services/graphql/schema/config';
import graphRequest from '@graphql_request';
import { storeConfigNameCookie } from '@config';
import LoadingView from '@core_modules/commons/Backdrop';
import helperCookies from '@helper_cookies';
import Cookie from 'js-cookie';
import Core from './core';

const Page = (props) => (
    <Core
        CategoryPage={CategoryPage}
        ProductPage={ProductPage}
        CmsPage={CmsPage}
        LoadingView={LoadingView}
        ProductLoader={ProductLoader}
        CategorySkeleton={CategorySkeleton}
        {...props}
    />
);

/**
 * get slug from query
 * namespacesRequired empty because Catalog page using product and category so only on component
 */
Page.getInitialProps = async ({ query, req }) => {
    let storeConfig;
    if (typeof window === 'undefined' && !req.cookies[storeConfigNameCookie]) {
        storeConfig = await graphRequest(ConfigSchema);
        storeConfig = storeConfig.storeConfig;
    }

    const obj = {
        slug: query.slug,
        namespacesRequired: ['common', 'product', 'category', 'validate', 'catalog'],
        url_key: req
            ? `${req.protocol}://${req.get('host')}`
            : `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`,
    };

    if (storeConfig) {
        obj.storeConfig = storeConfig;
    }

    /*
    * ---------------------------------------------
    * GET VENDOR ID FROM COOKIES / STORESWITCHER
    * TO BE PROVIDED INTO PAGE PROPS
    */

    let allcookie = req ? req.cookies : {};
    if (typeof window !== 'undefined') {
        allcookie = Cookie.getJSON();
    }
    const getVendorIdValue = (tmp) => {
        try {
            return JSON.parse(tmp);
        } catch {
            return tmp;
        }
    };
    if (getVendorIdValue(allcookie.vendor_id)) {
        obj.vendorId = getVendorIdValue(allcookie.vendor_id);
    } else {
        const storeSwitcher = await graphRequest(`
            {
                storeSwitcher {
                    default_vendor_id
                    options {
                        vendor_id
                        vendor_name
                    }
                }
            }
        `);
        obj.vendorId = storeSwitcher && storeSwitcher.storeSwitcher && storeSwitcher.storeSwitcher.default_vendor_id;
        helperCookies.set('vendor_id', obj.vendorId);
    }
    return obj;
};

export default withApollo({ ssr: true })(withTranslation()(Page));
