/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PriceFormat from '@common_priceformat';
import Typography from '@common_typography';
import Link from 'next/link';
import React from 'react';
import useStyles from '../style';

const Detail = (props) => {
    const {
        spesificProduct, handleClick, name, __typename, price_range, price_tiers,
        special_from_date, special_to_date, vendor_product_data, url_key,
    } = props;
    const styles = useStyles();
    return (
        <div
            className={styles.descItem}
        >
            <PriceFormat
                // eslint-disable-next-line camelcase
                priceRange={spesificProduct.price_range ? spesificProduct.price_range : price_range}
                // eslint-disable-next-line camelcase
                priceTiers={spesificProduct.price_tiers ? spesificProduct.price_tiers : price_tiers}
                productType={__typename}
                specialFromDate={special_from_date}
                specialToDate={special_to_date}
                vendorCost={vendor_product_data[0].vendor_cost}
                vendorSpecialPrice={vendor_product_data[0].vendor_special_price}
                flashSalePrice={vendor_product_data[0].flash_sale_price}
                flashSaleFrom={vendor_product_data[0].flash_sale_from}
                flashSaleTo={vendor_product_data[0].flash_sale_to}
            />
            <Link href="/[...slug]" as={`/${url_key}`} className={styles.productLinkButton}>
                <a onClick={() => handleClick(props)}>
                    <Typography
                        variant="p"
                        className={styles.productTitle}
                        letter="capitalize"
                    >
                        {name}
                    </Typography>
                </a>
            </Link>
        </div>
    );
};

export default Detail;
